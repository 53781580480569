import React from "react"

const PaddingWrapper = ({ className, padding, children }) => {
  const { top, bottom } = padding || {}

  const classes = {
    className,
    ...top,
    ...bottom
  }

  const classKeys = Object.keys(classes)

  let styles = "post-content-wrapper"

  classKeys.forEach(key => {
    if (classes[key]) {
      styles += ` ${classes[key]}`
    }
  })

  return <div className={styles}>{children}</div>
}

export default PaddingWrapper
