// extracted by mini-css-extract-plugin
export var bgGrey100 = "ImageContent-module--bg-grey-100--a559b";
export var bgGrey150 = "ImageContent-module--bg-grey-150--c2131";
export var bgGrey200 = "ImageContent-module--bg-grey-200--b5601";
export var bgGrey300 = "ImageContent-module--bg-grey-300--a8bcc";
export var bgGrey400 = "ImageContent-module--bg-grey-400--3b235";
export var bgGrey500 = "ImageContent-module--bg-grey-500--2cb7b";
export var bgGrey600 = "ImageContent-module--bg-grey-600--a9f25";
export var bgGrey700 = "ImageContent-module--bg-grey-700--0278e";
export var bgGrey800 = "ImageContent-module--bg-grey-800--e2745";
export var bgGrey900 = "ImageContent-module--bg-grey-900--7410a";
export var imgCls = "ImageContent-module--imgCls--ac51a";
export var textGrey100 = "ImageContent-module--text-grey-100--14873";
export var textGrey150 = "ImageContent-module--text-grey-150--ccf50";
export var textGrey200 = "ImageContent-module--text-grey-200--cae5d";
export var textGrey300 = "ImageContent-module--text-grey-300--09273";
export var textGrey400 = "ImageContent-module--text-grey-400--514a0";
export var textGrey500 = "ImageContent-module--text-grey-500--e9abd";
export var textGrey600 = "ImageContent-module--text-grey-600--bffc2";
export var textGrey700 = "ImageContent-module--text-grey-700--ddec1";
export var textGrey800 = "ImageContent-module--text-grey-800--66d49";
export var textGrey900 = "ImageContent-module--text-grey-900--614f9";