import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

import { imgCls } from "./ImageContent.module.scss"
import PaddingWrapper from "./PaddingWrapper"

const ImageContent = ({ image, config }) => (
  <PaddingWrapper padding={config?.padding}>
    <figure>
      <GatsbyImage
        className={imgCls}
        image={getImage(image?.localFile)}
        alt={image?.altText}
      />
      {image?.caption && (
        <figcaption
          // className={style.imageCaption}
          dangerouslySetInnerHTML={{
            __html: image.caption,
          }}
        />
      )}
    </figure>
  </PaddingWrapper>
)

export default ImageContent
